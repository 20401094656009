<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .header-intro-generel-box {
    .title-box {
      span {
        
      }
    }
  }
  .digital-marketplaces-subpage {
    position: relative;
    .img-logo-overview {
      border-bottom:1px solid #000;
      border-top:1px solid #000;
      margin-top:30px;
      img {
        max-width:500px;
        margin:50px auto 40px auto;
        display:block;
      }
    }
 
  

  

    .brands-overview {
      margin-bottom: 30px;
      .col-lg-4 {
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        &:nth-child(3) {
          border-right: 0;
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          border-bottom: 0;
        }
        &:nth-child(5) {
          border-right: 0;
        }
        @media (max-width: $width-lg) {
          &:nth-child(2),
          &:nth-child(4) {
            border-right: 0;
          }
          &:nth-child(3) {
            border-right: 1px solid #ccc;
          }
          &:nth-child(4) {
            border-bottom: 1px solid #ccc;
          }
          &:nth-child(5) {
            border-bottom: 1px solid #ccc;
          }
        }
        @media (max-width: $width-md) {
          border-right: 0 !important;
          border-bottom: 0 !important;
        }
      }
    }
    .brands-overview-v2 {
      margin-bottom: 30px;
      .col-lg-4 {
        border-right: 1px solid #ccc;
        //border-bottom:1px solid #ccc;
        &:nth-child(2) {
          border-right: 0;
        }

        @media (max-width: $width-lg) {
        }
        @media (max-width: $width-md) {
          border-right: 0 !important;
          border-bottom: 0 !important;
        }
      }
    }

    .brands-overview-inner {
      display: block;
      //border:1px solid red;
      height: 300px;
      cursor: pointer;
      transition: $transition-std;

      @media (max-width: $width-lg) {
        height: 250px;
      }
      @media (max-width: $width-md) {
        height: 200px;
      }
      img,
      .title-text {
        display: block;
        margin: 0px auto;
        max-width: 250px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition-std;
      }
      .title-text {
        font-family: 'American Typewriter ITC W04 Md';
        font-size: 200%;
        text-align: center;
      }
      &:hover {
        transform: scale(0.85);

        img {
          opacity: 0.8;
        }
      }
    }

    .collapse-content {
      margin-top: 40px;
      .collapse-inner {
        background: #f1f1f1;
        padding: 25px;
      }
      .backto-overview {
        //border:1px solid blue;
        margin-bottom: 10px;
        display: block;
        position: relative;
        top: 0px;
        text-align: center;
        svg {
          width: 25px;
        }
      }
      h3 {
        text-transform: none !important;
        margin-bottom: 20px;
        margin-top: 10px;
      }
      .title-text-nachine-learning {
        font-family: 'American Typewriter ITC W04 Md';
        font-size: 200%;
        text-align: center;
      }
      img {
        max-width: 250px;
        display: block;
        margin: 15px auto;
      }
      .btn-contact {
        padding: 10px 0;

        a {
          display: block;
          background: $blue;
          padding: 10px 15px;
          color: #000;
          text-align: center;
          margin-right: 15px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .btn-std {
      text-align: left !important;
      margin-top: 30px;
    }
  }
}
</style>

<template>
  <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild.jpg')})` }" headerImageText="Styria Digital Marketplaces" />-->
  <SocialMediaIconBox />
  <div class="digital-marketplaces-subpage">
    <div class="container-fluid  img-logo-overview">
      <div class="row padding-tb-s">
        <div class="col-lg-12">
          <img src="@/assets/img/marken/brands/styria-marketplaces.svg" alt="Marketplaces" class="img-fluid" />
        </div>
      </div>
    </div>
    
   
    <div class="container">
      
      <div class="row header-section-box justify-content-center align-self-center">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">marketplaces</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1>Technology</h1>
        </div>
      </div>
      <div class="col-12 padding-tb-s">
        <img src="@/assets/img/marketplaces/technology-start.jpg" alt="Technology" class="img-fluid shadow margin-tb-s" />
      </div>

      <div class="brands-overview-v2 row justify-content-center" id="brands-overview-v2">
        <div class="col-lg-4 col-md-6 justify-content-center">
          <a href="#collapse-trikoder" v-smooth-scroll="{ offset: -200 }" class="brands-overview-inner" data-bs-toggle="collapse" data-bs-target="#collapse-willhaben" aria-expanded="false" aria-controls="collapse-willhaben">
            <div class="title-text">machine learning</div>
          </a>
        </div>

        <div class="col-lg-4 col-md-6 justify-content-center">
          <a href="#collapse-trikoder" v-smooth-scroll="{ offset: -200 }" class="brands-overview-inner" data-bs-toggle="collapse" data-bs-target="#collapse-trikoder" aria-expanded="false" aria-controls="collapse-trikoder">
            <img src="@/assets/img/marken/brands/trikoder.svg" alt="Trikoder" class="img-fluid" />
          </a>
        </div>
      </div>
     
    </div>
  </div>

  
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
