
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
